import React, {FC, useMemo, useRef, useState} from 'react';
import {Tooltip} from "primereact/tooltip";
import {classNames, classNames as cx} from "primereact/utils";
import styles from "../draftUsers.module.scss";
import TextFieldEditor from "../Editors/TextEditor";
import {useToast} from "../../../../hooks/useToast";
import {useMutation} from "@tanstack/react-query";
import {requests} from "../../../../services/http-common";
import {AxiosError} from "axios";
import {extractErrorMessage} from "../../../../helpers/extractErrorMessage";
import {TReactQueryReFetch} from "../../../../@types/global";
import numberToLocaleString from "../../../../helpers/numberToLocaleString";

interface TTableDataHolderProps {
    columnName: string;
    rowData: Record<string, string | number | Record<string, string>>;
    label: string;
    rowId: number;
    refetchDraftUsers: TReactQueryReFetch;
    companyId: number;
    setTableLoading: React.Dispatch<React.SetStateAction<boolean>>;
}
const TableDataHolder: FC<TTableDataHolderProps> = ({
columnName,
rowData,
rowId,
refetchDraftUsers,
companyId,
setTableLoading,
}) => {
    const [isEdit, updateEditMode] = useState(false);
    const tdValue = rowData[columnName] as string || '';
    const errors: Record<string, string> = rowData?.errors as Record<string, string>;
    const hasError = Object.prototype.hasOwnProperty.call(errors, columnName);
    const [columnWidth, setColumnWidth] = useState<number>(0);
    const tableDataRef = useRef<HTMLTableDataCellElement>(null);
    const errMessage = hasError ? errors[columnName] : '';

    const { show } = useToast();
    const onUpdateSingleFieldValue = useMutation({
        mutationFn: async (data: {
            rowId: number,
            params: Record<string, string | number>,
        }) => {
            const { rowId, params } = data;
            return await requests.put(
                `api/companies/${companyId}/file83b-draft/${rowId}`, params
            );
        },
        onSuccess: () => {
            refetchDraftUsers().then(() => {
                updateEditMode(false);
                setTableLoading(false);
            });
        },
        onError: (error: AxiosError<{ detail: string }>, variables) => {
            const errorData = {
                error: error,
                variables: variables,
                retryFn: onUpdateSingleFieldValue.mutate,
                show: show,
                cb: () => {
                    setTableLoading(false);
                }
            };
            extractErrorMessage(errorData);
        },
    });

    const onUpdateRowInfo = (params: Record<string, string | number>, rowId: number) => {
        setTableLoading(true);
        onUpdateSingleFieldValue.mutate({ rowId, params });
    }

    const submitUpdate = (values: Record<string, string>) => {
        onUpdateRowInfo(values, rowId);
    }

    const handleClick = () => {
        const el = tableDataRef.current;
        setColumnWidth(el?.offsetWidth as number);
    }
    const handleDoubleClick = () => {
        setTimeout(() => {
            updateEditMode(true);
        }, 200);
    }
    const valuePlaceholder = useMemo(
        () => {
            const emptySymbol = `--`;
            let finalValue;
            switch (columnName) {
                case 'numberOfShares': {
                    if (tdValue && !hasError) {
                        const numberedStrValue = Number(tdValue);
                        const isValidNumber = !isNaN(numberedStrValue);
                        finalValue = isValidNumber ? numberToLocaleString(numberedStrValue) : tdValue;
                    } else {
                        finalValue = tdValue || emptySymbol;
                    }
                    break;
                }
                default: {
                    finalValue = tdValue || emptySymbol;
                }
            }
            return finalValue;
        }, [tdValue, columnName]
    )
    if (isEdit) {
        return (
            <TextFieldEditor
                fieldName={columnName}
                fieldValue={tdValue}
                submitUpdate={submitUpdate}
                updateEditMode={updateEditMode}
                isEmail={columnName === 'firstRecipientEmail' || columnName === 'secondRecipientEmail'}
                columnWidth={columnWidth}
            />
        )
    }
    return (
        <td className={styles.tableData} ref={tableDataRef}>
            <div
                onDoubleClick={handleDoubleClick}
                onClick={handleClick}
                className={classNames(styles.dataHolder, {
                [styles.notValid]: hasError,
                [styles.typeOfRestriction]: columnName === 'typeOfRestriction'
            })}>
                <p title={tdValue} className={styles.valueHolder}>{valuePlaceholder}</p>
                {
                    hasError && (
                        <span className={styles.iconHolder}>
                              <Tooltip target=".person-label" />
                              <i
                                  data-pr-tooltip={errMessage}
                                  data-pr-position="top"
                                  className={cx(
                                      'person-label pi pi-info-circle',
                                      styles.tooltipIcon,
                                  )}
                              >
                              </i>
                        </span>
                    )
                }
            </div>
        </td>
    )
}

export default TableDataHolder;
