import React, {FC, useState} from 'react';
import usedIcon from '../../assets/icons/used-sub.svg';
import availableIcon from '../../assets/icons/available-sub.svg';

import styles from './subscriptions.module.scss';
import {useMutation} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {extractErrorMessage} from "../../helpers/extractErrorMessage";
import {requests} from "../../services/http-common";
import {useToast} from "../../hooks/useToast";
import ButtonPrimary from "../../uiComponents/ButtonPrimary/ButtonPrimary";
import EmbeddedPayment from "../../components/DocumentGeneration/components/EmbeddedPayment/EmbeddedPayment";

interface TSubscriptionsProps {
    file83bLimit: number;
    remainingCount: number;
    companyId: number;
}

const Subscriptions: FC<TSubscriptionsProps> = ({
file83bLimit,
remainingCount,
companyId,
}) => {
    const [clientSecret, setClientSecret] = useState('');
    const { show } = useToast();
    const getEmbeddedPaymentUrl = useMutation({
        onError: (error: AxiosError<{ message: string }>, variables) => {
            const errorData = {
                error: error,
                variables: variables,
                retryFn: getEmbeddedPaymentUrl.mutate,
                show: show,
                cb: () => {}
            };
            extractErrorMessage(errorData);
        },
        onSuccess: (res) => {
            const embeddedData = res?.embeddedData;
            const clientSecret = embeddedData?.clientSecret;
            setClientSecret(clientSecret);
        },
        mutationFn: async () => await requests.get(`/api/checkout/${companyId}/create-company-session`),
    });
    const getUrl = () => {
        getEmbeddedPaymentUrl.mutate();
    }
  return (
    <div className={styles.subscriptions}>
        <div className={styles.grid}>
            <div className={styles.item}>
                <div className={styles.row}>
                    <img src={usedIcon} alt=""/>
                    <span>Used Sub</span>
                </div>
                <p>{file83bLimit - remainingCount}</p>
            </div>
            <div className={styles.item}>
                <div className={styles.row}>
                    <img src={availableIcon} alt=""/>
                    <span>Available Sub</span>
                </div>
                <p>{remainingCount}</p>
            </div>
        </div>
        <div className={styles.payButtonWrapper}>
            <ButtonPrimary
                label="Pay"
                styleType="primaryOutline"
                onClick={getUrl}
            />
        </div>
        {
            clientSecret && (
                <EmbeddedPayment clientSecret={clientSecret} />
            )
        }
    </div>
  );
};

export default Subscriptions;
